(() => {
  'use strict';

  // Utility function to change the theme icon
  const updateThemeIcon = (theme) => {
    const themeIcon = document.querySelector('.active-theme-icon');
    if (themeIcon) {
      const themeIcon = document.querySelector('.active-theme-icon');
      themeIcon.classList.remove('fa-sun-bright', 'fa-moon-stars', 'fa-circle-half-stroke');
      switch (theme) {
        case 'light':
          themeIcon.classList.add('fa-sun-bright');
          break;
        case 'dark':
          themeIcon.classList.add('fa-moon-stars');
          break;
        case 'auto':
          themeIcon.classList.add('fa-circle-half-stroke');
          break;
      }
    }
  };

  const getStoredTheme = () => localStorage.getItem('theme');
  const setStoredTheme = theme => localStorage.setItem('theme', theme);
  
  const getPreferredTheme = () => {
    const storedTheme = getStoredTheme();
    if (storedTheme) {
      return storedTheme;
    }
    // Default to light if no preference is stored
    return 'light';
    
    // DEFAULT TO SYSTEM SETTING
    // return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  };


  const setTheme = theme => {
    if (theme === 'auto' && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      document.documentElement.setAttribute('data-bs-theme', 'dark');
    } else {
      document.documentElement.setAttribute('data-bs-theme', theme);
    }
    updateThemeIcon(theme); // Update the icon based on the theme
  };
  setTheme(getPreferredTheme());
  const showActiveTheme = (theme, focus = false) => {
    const themeSwitcher = document.querySelector('#bd-theme');
    if (!themeSwitcher) {
      return;
    }
    const themeSwitcherText = document.querySelector('#bd-theme-text');
    document.querySelectorAll('[data-bs-theme-value]').forEach(element => {
      element.classList.remove('active');
      element.setAttribute('aria-pressed', 'false');
    });
    const btnToActive = document.querySelector(`[data-bs-theme-value="${theme}"]`);
    btnToActive.classList.add('active');
    btnToActive.setAttribute('aria-pressed', 'true');
    const themeSwitcherLabel = `${themeSwitcherText.textContent} (${btnToActive.dataset.bsThemeValue})`;
    themeSwitcher.setAttribute('aria-label', themeSwitcherLabel);
    if (focus) {
      themeSwitcher.focus();
    }
    updateThemeIcon(theme); // Ensure icon is updated when theme is shown
  };
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
    const storedTheme = getStoredTheme();
    if (storedTheme !== 'light' && storedTheme !== 'dark') {
      setTheme(getPreferredTheme());
    }
  });
  window.addEventListener('DOMContentLoaded', () => {
    showActiveTheme(getPreferredTheme());
    document.querySelectorAll('[data-bs-theme-value]').forEach(toggle => {
      toggle.addEventListener('click', (e) => {
        const theme = toggle.getAttribute('data-bs-theme-value');
        setStoredTheme(theme);
        setTheme(theme);
        showActiveTheme(theme, true);
      });
    });
  });
})();

$(document).on('click', '.download-trigger', function () {
    let source = $(this).data('source'); // Detect if this is guestbook or gallery
    $('#media_download').attr('data-source', source); // Store it in modal
});

$(document).on('submit', '#request_download', function (e) {
    e.preventDefault();
    let currentGalleryId = $('#gallery').data('galleryid');
    let downloadMediaIDs = getMediaSelection(currentGalleryId);
    let notifyName = $('#notifyName').val();
    let notifyEmail = $('#notifyEmail').val();
    let dataSource = $('#media_download').attr('data-source');

    // Check if there are selected images
    if(downloadMediaIDs.length > 0) {
        $.ajax({
            method: 'POST',
            url: '/inc/actions/ajax/add-download-queue.ajax.php',
            dataType: 'json',
            data: { 
                mediaIDs: downloadMediaIDs, 
                galleryID: currentGalleryId, 
                notifyName: notifyName,
                notifyEmail: notifyEmail,
                dataSource: dataSource
            },
        })
        .done(function (result) {
            let messageObject = [{
                type: {
                    notificationTypeHideDelay: '2000',
                    notificationTypeAutoHide: '0',
                    notificationTypeIconClass: result.success ? 'fa-light fa-square-check' : 'fa-light fa-square-cross',
                    notificationTypeName: result.success ? 'Success' : 'Error'
                },
                timestamp: new Date().toLocaleString(),
                message: result.message
            }];
            make_toast(messageObject);
    
            // Close modal after request
            const download_modal = bootstrap.Modal.getInstance(document.getElementById('media_download'));
            download_modal.hide();
        })
        .fail(function (xhr, status, error) {
            // Handle the AJAX request failure if needed
            // console.error(xhr, status, error);
            // Handle the error here
        });
    } else {
        alert('No media selected.');
    }
});

/**
 * Extracts the gallery ID from the current URL.
 * 
 * This function parses the URL path, filters out empty parts,
 * and searches for a numeric segment which is assumed to be the gallery ID.
 * 
 * @returns {string|undefined} The gallery ID if found, undefined otherwise.
 */

function getGalleryIdFromURL() {
  let urlParts = window.location.pathname.split('/').filter(part => part !== '')
  let galleryID = urlParts.reverse().find(part => /^\d+$/.test(part))
  return galleryID
}

/**
 * Retrieves the media selection for a specific gallery from local storage.
 * 
 * This function constructs a key using the gallery ID and retrieves the media selection
 * from local storage. If no selection exists, it returns an empty array.
 * 
 * @param {string} galleryID - The ID of the gallery to retrieve media selection for.
 * @returns {Array} An array of media IDs representing the selected items.
 */
function getMediaSelection(galleryID) {
  let key = 'media_selection_gallery_' + galleryID
  return JSON.parse(localStorage.getItem(key)) || []
}

/**
 * Updates the media selection for a specific gallery in local storage.
 * 
 * This function constructs a key using the gallery ID and updates the local storage
 * with the provided media selection array.

 * @param {string} galleryID - The ID of the gallery to update media selection for.
 * @param {Array} selection - The array of media IDs to be stored.
 */
function updateMediaSelection(galleryID, selection) {
  let key = 'media_selection_gallery_' + galleryID
  localStorage.setItem(key, JSON.stringify(selection))
}

/**
 * Updates the SVG icons for the media items in the gallery.
 * 
 * This function updates the SVG icons for the media items in the gallery based on the current media selection.
 * It toggles the selected state of the media items and updates the SVG icons to reflect the current selection.
 * 
 * @param {string} galleryID - The ID of the gallery to update SVG icons for.
 */

function updateSVGIcons(galleryID) {
  let media_selection = getMediaSelection(galleryID)
  let totalMediaItems = $('.media-item').length
  $('.selection-count').html(media_selection.length)
  if (media_selection.length > 0) {
    $('.media-toolbox').css('display', 'block').fadeTo(200, 1)
  } else {
    $('.media-toolbox').fadeTo(200, 0, function () {
      $(this).css('display', 'none')
    })
  }
  $('.select-all').toggleClass(
    'active',
    media_selection.length === totalMediaItems,
  )
  $('.media-item').each(function () {
    let mediaID = $(this).data('mediaid')
    let svg = $(this).find('.select-media').find('[data-fa-i2svg]')
    let card = $(this).find('.card')
    let squareIcon = $(this).find('.fa-square')
    if ($.inArray(mediaID, media_selection) >= 0) {
      squareIcon.toggleClass('fa-square fa-square-check fa-light fa-solid')
      svg.attr('data-icon', 'square-check').attr('data-prefix', 'fas')
      $(this).addClass('selected')
      card.addClass('selected')
    } else {
      svg.attr('data-icon', 'square').attr('data-prefix', 'fal')
      $(this).removeClass('selected')
      card.removeClass('selected')
    }
  })
}

function get_gallery_by_galleryID(galleryID) {
  return new Promise((resolve, reject) => {
    $.ajax({
      url: '/inc/actions/ajax/get-gallery.ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        galleryID: galleryID,
      },
      success: function (response) {
        resolve(response)
      },
      error: function (xhr, status, error) {
        reject(error)
      },
    })
  })
}

document.addEventListener('DOMContentLoaded', function () {
  document.addEventListener('click', function (e) {
    if (e.target.classList.contains('copy-input-btn')) {
      e.preventDefault()
      const btn = e.target
      const origBtnText = btn.innerHTML
      const inputGroup = btn.closest('.input-group')
      const input = inputGroup.querySelector('.copy-input-target')

      if (input) {
        input.select()
        const value = input.value
        navigator.clipboard
          .writeText(value)
          .then(() => {
            btn.classList.add('active')
            btn.textContent = 'Copied!'
            setTimeout(() => {
              btn.innerHTML = origBtnText
              btn.classList.remove('active')
            }, 2000)
          })
          .catch(err => {
            console.error('Error copying text: ', err)
          })
      }
    }
  })

  let currentGalleryId = getGalleryIdFromURL()
  let media_selection = getMediaSelection(currentGalleryId)
  if (typeof favouriteMediaJSON !== 'undefined') {
    let favouriteMedia = JSON.parse(favouriteMediaJSON)
    updateFavourites(currentGalleryId, favouriteMedia)
  }
  let favourites = getFavourites(currentGalleryId)

  /* Toggle icons for Favourites */
  $('.media-item').each(function () {
    let mediaID = $(this).attr('data-mediaid')
    let isFavourite = $.inArray(mediaID, favourites) >= 0
    toggleFavouriteIcon(mediaID, isFavourite)
  })
  /* Update Icons for Selected items */
  updateSVGIcons(currentGalleryId)

  /* SELECT MEDIA */
  $(document).on('click', '.select-media', function (e) {
    e.preventDefault()
    let mediaID = $(this).data('mediaid')
    let found = jQuery.inArray(mediaID, media_selection)
    if (found >= 0) {
      media_selection.splice(found, 1)
    } else {
      media_selection.push(mediaID)
    }
    updateMediaSelection(currentGalleryId, media_selection)
    updateSVGIcons(currentGalleryId)
  })
  $(document).on('click', '.select-all', function (e) {
    $(this).addClass('active')
    $('.media-item').each(function () {
      let mediaID = $(this).data('mediaid')
      if ($.inArray(mediaID, media_selection) === -1) {
        media_selection.push(mediaID)
      }
    })
    updateMediaSelection(currentGalleryId, media_selection)
    updateSVGIcons(currentGalleryId)
  })
  $(document).on('click', '.select-none', function (e) {
    $('.select-all').removeClass('active')
    media_selection = []
    updateMediaSelection(currentGalleryId, media_selection)
    updateSVGIcons(currentGalleryId)
  })

  /* NEW SELECT FUNCTIONALITY */
  let selectionMode = false

  $(document).on('click', '.gallery-select-toggle', function (e) {
    e.preventDefault()
    e.stopPropagation()

    selectionMode = !selectionMode

    if (selectionMode) {
      $('main').addClass('selectmode')
      $(this).addClass('active')
      $('.gallery-select-toggle .action-icon')
        .removeClass('fa-light fa-square')
        .addClass('fa-square-check')
      $('.gallery-select-toggle .action-label').text('Exit Selection')
      $('.media-toolbox').fadeIn()

      // Destroy the lightGallery instance if it exists
      if (window.lgInstance) {
        window.lgInstance.destroy(true)
        window.lgInstance = null
      }
    } else {
      $('main').removeClass('selectmode')
      $(this).removeClass('active')
      $('.gallery-select-toggle .action-icon')
        .removeClass('fa-square-check')
        .addClass('fa-light fa-square')
      $('.gallery-select-toggle .action-label').text('Select Items')
      $('.media-toolbox').fadeOut()
      $('.media-item').removeClass('selected')
      media_selection = []
      updateMediaSelection(currentGalleryId, media_selection)

      reinitializeLightGallery()
    }

    updateSVGIcons(currentGalleryId)
  })

  $(document).on('click', '.media-item', function (e) {
    if (!selectionMode) return

    let mediaID = $(this).data('mediaid')
    let index = media_selection.indexOf(mediaID)

    if (index > -1) {
      media_selection.splice(index, 1)
    } else {
      media_selection.push(mediaID)
    }

    updateMediaSelection(currentGalleryId, media_selection)
    updateSVGIcons(currentGalleryId)
    e.preventDefault()
  })

  // Ensure that media-toolbox still works with the new button
  updateSVGIcons(currentGalleryId)

  // Optional: Add overlay icon on selection
  $('.gallery-grid .media-item .card').append(
    '<span class="select-overlay"><i class="fa-solid fa-check-circle"></i></span>',
  )

  /* DELETE MEDIA */
  $(document).on('click', '.delete-media', function (e) {
    e.preventDefault()
    let opentl = $(this).data('opentl')
    let closedtl = $(this).data('closedtl')
    if ($(this).siblings('.confirm-delete-media').hasClass('hide')) {
      $(this).text(opentl)
    } else {
      $(this).text(closedtl)
    }
    $(this).siblings('.confirm-delete-media').toggleClass('hide')
  })
  $(document).on('click', '.confirm-delete-media', function (e) {
    e.preventDefault()
    if (media_selection.length > 0) {
      $.ajax({
        method: 'POST',
        url: '/inc/actions/delete/media.delete.php',
        dataType: 'json',
        data: {
          action: 'delete',
          galleryID: currentGalleryId,
          mediaIDs: media_selection,
        },
      })
        .done(function (result) {
          if (result.success) {
            let messageObject = [
              {
                type: {
                  notificationTypeHideDelay: '2000',
                  notificationTypeAutoHide: '1',
                  notificationTypeIconClass: 'fa-light fa-square-check',
                  notificationTypeName: 'Success',
                },
                timestamp: new Date().toLocaleString(),
                message: result.message,
              },
            ]
            make_toast(messageObject)
            media_selection.forEach(function (mediaID) {
              $('#media_' + mediaID).remove()
            })
            // UPDATE #trash_count in .title-hero

            media_selection = []
            updateMediaSelection(currentGalleryId, media_selection)
            updateSVGIcons(currentGalleryId)
          }
        })
        .fail(function (xhr, status, error) {
          // Handle the AJAX request failure if needed
          // console.error(xhr, status, error);
          // Handle the error here
        })
    }
  })

  $(document).on('click', '.restore-media', function (e) {
    e.preventDefault()
    let mediaID = $(this).data('mediaid')
    // DO AJAX
    $.ajax({
      url: '/inc/actions/ajax/restore-media.ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        mediaID: mediaID,
      },
      success: function (response) {
        let messageObject = [
          {
            type: {
              notificationTypeHideDelay: '2000',
              notificationTypeAutoHide: '1',
              notificationTypeIconClass: 'fa-light fa-square-check',
              notificationTypeName: 'Success',
            },
            timestamp: new Date().toLocaleString(),
            message: response.message,
          },
        ]
        make_toast(messageObject)
        $('#media_' + mediaID).remove()
      },
      error: function (xhr, status, error) {
        //                console.error("Error occurred: " + status + ", " + error);
      },
    })
  })

  /* GALLERY SETTINGS */

  $(document).on('click', '#isPrivate', function (e) {
    let isChecked = $(this).is(':checked')
    $('#gallery_password_wrapper').toggle(isChecked)
  })

  $(document).on('click', '.gallery-settings', async function (e) {
    e.preventDefault()
    let modal = $('#gallery_settings')
    let selected = $(this).parents('.gallery-item')
    let selectedID = selected.attr('data-galleryid')
    try {
      let gallery = await get_gallery_by_galleryID(selectedID)
      modal.removeAttr('data-galleryid').attr('data-galleryid', selectedID)

      let qrGalleryPublicUrl =
        window.location.origin +
        '/q/' +
        gallery.userID +
        '/' +
        gallery.gallerySlug +
        '/' +
        gallery.galleryID +
        '/'
      $('#qrGalleryPublicUrl').val(qrGalleryPublicUrl)

      $('#gallery_settings_poster').attr('src', gallery.galleryPosterUrl)
      $('#galleryName').val(gallery.galleryName)
      $('#galleryDescription').val(gallery.galleryDescription)
      $('#dateCreated').val(gallery.dateCreated)
      $('#activatedDate').val(gallery.activatedDate)
      if (gallery.expiryDate) {
        // Convert to "YYYY-MM-DDTHH:MM:SS"
        let formattedExpiryDate = gallery.expiryDate.replace(' ', 'T')
        $('#expiryDate').val(formattedExpiryDate)
      } else {
        $('#expiryDate').val('')
      }
      $('#galleryUserID').val(gallery.userID)
      let isPrivateChecked = gallery.isPrivate === 1
      $('#isPrivate').prop('checked', isPrivateChecked)
      let isUploadableChecked = gallery.isUploadable === 1
      $('#isUploadable').prop('checked', isUploadableChecked)
      let isDownloadableChecked = gallery.isDownloadable === 1
      $('#isDownloadable').prop('checked', isDownloadableChecked)
      let guestbookEnabledChecked = gallery.guestbookEnabled === 1
      $('#guestbookEnabled').prop('checked', guestbookEnabledChecked)
      let audioGuestbookEnabledChecked = gallery.audioGuestbookEnabled === 1
      $('#audioGuestbookEnabled').prop('checked', audioGuestbookEnabledChecked)
      $('#gallery_password_wrapper').toggle(isPrivateChecked)
      $('#galleryPassword').val(gallery.galleryPassword)

      let timestamp = new Date().getTime()
      $('#preview_qr_code').attr('src', gallery.qrCodeUrl + '?t=' + timestamp)
      $('#preview_qr_custom_image').attr(
        'src',
        gallery.qrImageUrl + '?t=' + timestamp,
      )
      // $('#preview_qr_code').attr('src', gallery.qrCodeUrl);
      // $('#preview_qr_custom_image').attr('src', gallery.qrImageUrl);

      let qrType = gallery.qrType || 'standard'
      $('#qrType').val(qrType)

      let qrFormat = gallery.qrFormat || 'png32'
      $('#qrFormat').val(qrFormat)

      let qrImagePosition = gallery.qrImagePosition
      $('#qrImagePosition').val(qrImagePosition)

      $('.download-qr')
        .attr('href', gallery.qrCodeUrl)
        .attr('download', 'QRCode.png')

      // $('#qrImageUrl').val(gallery.qrImageUrl);
      updateQrTypeSettings()

      let statusID = gallery.statusID || '1'
      $('#statusID').val(statusID)

      console.warn('statusID = ' + statusID)

      $('#delete_gallery_confirm')
        .removeAttr('data-galleryid')
        .attr('data-galleryid', selectedID)
      $('#delete_gallery_confirm')
        .removeAttr('data-userid')
        .attr('data-userid', gallery.userID)
    } catch (error) {
      // console.error('Failed to load gallery:', error);
    }
  })

  // Function to update the visibility of elements based on qrType value
  function updateQrTypeSettings() {
    var qrTypeValue = $('#qrType').val()

    if (qrTypeValue === 'standard') {
      // Hide elements and set qrImagePosition to "center"
      $(
        '#qr_image_position_wrapper, #qr_image_upload_wrapper, #qr_image_wrapper',
      ).hide()
      //             $('#qrImagePosition').val("center").trigger('change');
    } else {
      // Show elements if qrType is not "standard"
      $(
        '#qr_image_position_wrapper, #qr_image_upload_wrapper, #qr_image_wrapper',
      ).show()
    }
  }

  // Bind the function to the change event of qrType
  $(document).on('change', '#qrType', function () {
    updateQrTypeSettings()
  })

  // Call the function on page load to ensure correct initial state
  updateQrTypeSettings()

  $(document).on('click', '#regenQR', async function (e) {
    e.preventDefault()
    let qrSelected = $(this).closest('.gallery-settings-modal')
    let qrSelectedID = qrSelected.attr('data-galleryid')

    try {
      let qrGallery = await get_gallery_by_galleryID(qrSelectedID)
      $('#preview_qr_code').attr('src', '/images/loader.png')

      // CHECK SUBMITTED DATA
      let qrFormat = $('#qrFormat').val()
      let qrImagePosition = 'center'
      let qrImage = ''

      let qrType = $('#qrType').val()

      if (qrType !== 'standard') {
        // qrImagePosition = $('#qrImagePosition').val();
        qrImagePosition = ''
        // qrImage = $('#qrImage')[0].files.length > 0 ? $('#qrImage')[0].files[0] : '';
        // qrImage = $('#qrImage')[0].files[0];
        qrImage = ''
      }
      // Create FormData and append data
      let formData = new FormData()
      formData.append('qrType', qrType)
      formData.append('qrImagePosition', qrImagePosition)
      formData.append('qrFormat', qrFormat)
      formData.append('userID', qrGallery.userID)
      formData.append('galleryID', qrGallery.galleryID)

      if (qrImage) {
        // Only append image if file is selected
        formData.append('qrImage', qrImage)
      }

      $.ajax({
        url: '/qr-generate.php',
        type: 'POST',
        data: formData,
        dataType: 'json',
        processData: false,
        contentType: false,
        success: function (qrResponse) {
          d = new Date()
          $('#preview_qr_code').attr(
            'src',
            qrResponse.qrCodeUrl + '?' + d.getTime(),
          )
          $('.download-qr')
            .attr('href', qrResponse.qrCodeUrl)
            .attr('download', 'QRCode.png')

          if (qrResponse.qrImageUrl) {
            // Check if the qrImageUrl is not an empty string
            $('#preview_qr_custom_image').attr(
              'src',
              qrResponse.qrImageUrl + '?' + d.getTime(),
            )
          } else {
            $('#preview_qr_custom_image').attr('src', '') // Set src to an empty string if qrImageUrl is empty
          }
        },
        error: function (xhr, status, error) {
          // console.error("Error occurred: " + status + ", " + error);
        },
      })
    } catch (error) {
      // console.error('Failed to load gallery:', error);
    }
  })

  $(document).on('click', '.share-gallery', async function (e) {
    e.preventDefault()
    let shareModal = $('#share_gallery')
    let selected = $(this).parents('.gallery-item')
    let selectedID = selected.attr('data-galleryid')
    try {
      shareModal.removeAttr('data-galleryid').attr('data-galleryid', selectedID)
      let gallery = await get_gallery_by_galleryID(selectedID)
      $('#share_gallery_poster').attr('src', gallery.galleryPosterUrl)

      let galleryPublicUrl =
        window.location.origin +
        '/q/' +
        gallery.userID +
        '/' +
        gallery.gallerySlug +
        '/' +
        gallery.galleryID +
        '/'
      $('#galleryPublicUrl').val(galleryPublicUrl)

      $('#share_qr_code').attr('src', gallery.qrCodeUrl)
      $('.download-qr')
        .attr('href', gallery.qrCodeUrl)
        .attr('download', 'QRCode.png')
    } catch (error) {
      // console.error('Failed to load gallery:', error);
    }
  })

  $(document).on('click', '.download-qr', function (e) {
    e.preventDefault()
    var href = $(this).attr('href')

    // Create an anchor tag dynamically
    var link = document.createElement('a')
    link.href = href
    link.download = href.substring(href.lastIndexOf('/') + 1) // Assumes the filename is at the end of the URL
    document.body.appendChild(link)
    link.click() // Force download
    document.body.removeChild(link)
  })

  $(document).on('click', '.delete-gallery', function (e) {
    e.preventDefault()
    let opentl = $(this).data('opentl')
    let closedtl = $(this).data('closedtl')
    if ($(this).siblings('.confirm-delete-gallery').hasClass('hide')) {
      $(this).text(opentl)
    } else {
      $(this).text(closedtl)
    }
    $(this).siblings('.confirm-delete-gallery').toggleClass('hide')
  })

  $(document).on('click', '.confirm-delete-gallery', function (e) {
    e.preventDefault()

    let galleryID = $(this).attr('data-galleryid')
    let userID = $(this).attr('data-userid')
    $.ajax({
      method: 'POST',
      url: '/inc/actions/delete/gallery.delete.php',
      dataType: 'json',
      data: { action: 'delete', galleryID: galleryID, userID: userID },
    }).done(function (msg) {
      make_toast(msg)
      window.location.href = '/galleries/' + userID
    })
  })

  // RESTORE GALLERY
  $(document).on('click', '#restore_gallery', function (e) {
    e.preventDefault()

    let galleryID = $(this).attr('data-galleryid')
    let userID = $(this).attr('data-userid')
    
    // Store the button for potential UI updates
    const $button = $(this);
    
    // Store original button text
    const originalText = $button.text().trim();
    
    // Show loading state (without icon)
    $button.text('Restoring...');
    $button.prop('disabled', true);
    
    $.ajax({
      method: 'POST',
      url: '/inc/actions/restore/gallery.restore.php',
      data: { action: 'restore', galleryID: galleryID, userID: userID },
      // Don't set dataType to allow jQuery to auto-detect
    }).done(function (response) {
      // Create a success message
      let messageObject = [{
        type: {
          notificationTypeHideDelay: '2000',
          notificationTypeAutoHide: '1',
          notificationTypeIconClass: 'fa-light fa-square-check',
          notificationTypeName: 'Success'
        },
        timestamp: new Date().toLocaleString(),
        message: 'Gallery restored successfully'
      }];
      
      // Try to parse the response as JSON if it's a string
      if (typeof response === 'string') {
        try {
          response = JSON.parse(response);
          if (response.message) {
            messageObject[0].message = response.message;
          }
        } catch (e) {
          // If parsing fails, just use the default message
          console.log('Response is not valid JSON, using default message');
        }
      } else if (response && response.message) {
        // If response is already an object with a message property
        messageObject[0].message = response.message;
      }
      
      make_toast(messageObject);
      
      // Refresh the page after a short delay
      setTimeout(function() {
        window.location.href = '/galleries/' + userID;
      }, 1000);
    }).fail(function(xhr, status, error) {
      // Reset button state
      $button.text(originalText);
      $button.prop('disabled', false);
      
      // Create an error message
      let errorMessage = 'Error restoring gallery';
      if (error) {
        errorMessage += ': ' + error;
      }
      
      let messageObject = [{
        type: {
          notificationTypeHideDelay: '2000',
          notificationTypeAutoHide: '1',
          notificationTypeIconClass: 'fa-light fa-square-cross',
          notificationTypeName: 'Error'
        },
        timestamp: new Date().toLocaleString(),
        message: errorMessage
      }];
      
      make_toast(messageObject);
    });
  })

  // Handle click on activate button to show/hide confirm button
  $(document).on('click', 'button.activate-gallery', function (e) {
    e.preventDefault()
    let opentl = $(this).data('opentl')
    let closedtl = $(this).data('closedtl')
    
    if ($(this).siblings('.confirm-activate-gallery').hasClass('hide')) {
      $(this).html('<span class="fa-light fa-lock"></span> ' + opentl)
    } else {
      $(this).html('<span class="fa-light fa-lock"></span> ' + closedtl)
    }
    
    $(this).siblings('.confirm-activate-gallery').toggleClass('hide')
  })

  // Handle click on confirm activate button
  $(document).on('click', '.confirm-activate-gallery', async function (e) {
    e.preventDefault()

    let galleryID = $(this).attr('data-galleryid')
    let userID = $(this).attr('data-userid')
    
    $.ajax({
      method: 'POST',
      url: '/inc/actions/activate/galleries.activate.php',
      dataType: 'json',
      data: { action: 'activate', galleryID: galleryID, userID: userID },
    }).done(function (msg) {
      make_toast(msg)
      if (msg.success) {
        window.location.href = '/galleries/' + userID + '/' + galleryID + '/'
      } else {
        window.location.href = '/galleries/' + userID
      }
    })
  })

  /* MEDIA SETTINGS */
  // $(document).on('click', '.media-settings', function (e) {
  //   e.preventDefault()
  //   let modal = $('#media_settings')
  //   let selectedID, selectedImage, selectedImageDownload
    
  //   // If this is the lightgallery toolbar button
  //   if ($(this).closest('.lg-toolbar').length) {
  //       const currentSlide = document.querySelector('.lg-current .lightgallery-item')
  //       if (currentSlide) {
  //           selectedID = $(currentSlide).data('mediaid')
  //           // For images, data-src contains the full URL
  //           selectedImage = currentSlide.getAttribute('data-src')
  //           selectedImageDownload = selectedImage
  //           // For videos, we need to get the poster image
  //           if (currentSlide.hasAttribute('data-poster')) {
  //               selectedImage = currentSlide.getAttribute('data-poster')
  //           }
  //       }
  //   } else {
  //       // Original behavior for gallery grid buttons
  //       let selected = $(this).parents('.media-item')
  //       selectedID = $(this).data('mediaid')
  //       selectedImage = selected.find('img').attr('src')
  //       selectedImageDownload = selected.find('a').attr('href')
  //   }

  //   console.log(selectedID, selectedImage, selectedImageDownload)

  //   $('#media_settings_image').attr('src', selectedImage)
  //   $('#media_download_link').attr('href', selectedImageDownload)

  //   // Rest of your existing handler code
  //   let currentPosterID = $('.title-hero').attr('data-posterid')
  //   if (selectedID == currentPosterID) {
  //       $('#media_settings_hero').prop('checked', true)
  //   } else {
  //       $('#media_settings_hero').prop('checked', false)
  //   }
  //   let galleryID = getGalleryIdFromURL()
  //   let favourites = getFavourites(galleryID)
  //   let isFavourite = favourites.includes(selectedID.toString())
  //   modal.removeAttr('data-mediaid').attr('data-mediaid', selectedID)
  //   modal
  //       .find('.favourite-media')
  //       .removeAttr('data-mediaid')
  //       .attr('data-mediaid', selectedID)
  //   modal
  //       .find('.generate-thumbnail')
  //       .removeAttr('data-mediaid')
  //       .attr('data-mediaid', selectedID)
  //   toggleFavouriteIcon(selectedID, isFavourite)
  // })

  // Add this function to fetch media data by ID
function getMediaById(mediaID) {
  return $.ajax({
      url: '/inc/actions/ajax/get-media.ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
          mediaID: mediaID
      }
  });
}

$(document).on('click', '.media-settings', function(e) {
  e.preventDefault();
  const button = $(this);
  const mediaID = button.attr('data-mediaid');
  const modal = $('#media_settings');
    
  // Immediately update the modal's data-mediaid attribute
  modal.attr('data-mediaid', mediaID);
  modal.find('.favourite-media').attr('data-mediaid', mediaID);
  modal.find('.generate-thumbnail').attr('data-mediaid', mediaID);
  
  // Show loading state
  $('#media_settings_image').attr('src', '/images/loader.png');
  
  // Fetch media data
  getMediaById(mediaID).then(function(media) {
      
      $('#media_settings_image').attr('src', media.thumbnailUrl);
      $('#media_download_link').attr('href', media.mediaUrl);
      
      const currentPosterID = $('.title-hero').attr('data-posterid');
      $('#media_settings_hero').prop('checked', mediaID == currentPosterID);
      
      const galleryID = getGalleryIdFromURL();
      const favourites = getFavourites(galleryID);
      const isFavourite = favourites.includes(mediaID.toString());
      
      toggleFavouriteIcon(mediaID, isFavourite);
      
  }).catch(function(error) {
      // console.error('Error fetching media data:', error);
  });
});

  /* FAVOURITES */

  function getFavourites(galleryID) {
    let key = 'favourites_gallery_' + galleryID
    return JSON.parse(localStorage.getItem(key)) || []
  }
  window.getFavourites = getFavourites

  function updateFavourites(galleryID, favourites) {
    let key = 'favourites_gallery_' + galleryID
    localStorage.setItem(key, JSON.stringify(favourites))
  }
  function toggleFavouriteIcon(mediaID, isFavourite) {
    const mediaItems = [
      $('.media-item[data-mediaid="' + mediaID + '"]'),
      $('.media-settings-modal[data-mediaid="' + mediaID + '"]'),
    ].filter(item => item.length > 0)
    mediaItems.forEach(mediaItem => {
      const svg = mediaItem.find('.favourite-media [data-fa-i2svg]')
      const card = mediaItem.find('.card')
      const heartIcon = mediaItem.find('.fa-heart')
      toggleIcon(
        heartIcon,
        isFavourite,
        'fa-light favourite',
        'fa-solid favourite',
      )
      card.toggleClass('favourite', isFavourite)
      svg.attr('data-prefix', isFavourite ? 'fas' : 'fal')
    })

    // Now also update the lightGallery toolbar icon if the current slide's mediaID matches
    const container = document.getElementById('lightgallery')
    if (container && window.lgInstance) {
      // Get the original items (which hold our data-mediaid attributes)
      let originalItems = container.querySelectorAll('a.lightgallery-item')
      let currentIndex = window.lgInstance.index
      if (originalItems[currentIndex]) {
        let currentMediaID =
          originalItems[currentIndex].getAttribute('data-mediaid')
        // Make sure to compare in the same type (convert to Number, for instance)
        if (Number(currentMediaID) === Number(mediaID)) {
          let favBtn = document.querySelector('.lg-favourite-btn')
          if (favBtn) {
            favBtn.innerHTML = isFavourite
              ? '<i class="fa-solid fa-heart"></i>'
              : '<i class="fa-light fa-heart"></i>'
          }
        }
      }
    }
  }
  function toggleFavourite(mediaID) {
    let galleryID = getGalleryIdFromURL()
    let favourites = getFavourites(galleryID)
    let foundIndex = jQuery.inArray(mediaID, favourites)
    let isAlreadyFavourite = foundIndex >= 0
    let isFavourite = isAlreadyFavourite ? 0 : 1

    $.ajax({
      url: '/inc/actions/ajax/favourite-media.ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        mediaID: mediaID,
        favourite: isFavourite,
      },
      success: function (response) {
        if (isAlreadyFavourite) {
          favourites.splice(foundIndex, 1) // Remove from favourites
          toggleFavouriteIcon(mediaID, false)
        } else {
          favourites.push(mediaID) // Add to favourites
          toggleFavouriteIcon(mediaID, true)
        }
        updateFavourites(galleryID, favourites)
        $('#favourite_count').html(favourites.length)
        if (favourites.length > 0) {
          $('.favourites-link').removeClass('d-none')
        } else {
          $('.favourites-link').addClass('d-none')
        }
      },
      error: function (xhr, status, error) {
        console.error('Error toggling favourite: ', error)
      },
    })
  }
  window.toggleFavourite = toggleFavourite

  // Helper function to toggle classes concisely
  function toggleIcon(icon, isActive, inactiveClass, activeClass) {
    icon
      .toggleClass(inactiveClass, !isActive)
      .toggleClass(activeClass, isActive)
  }

  // Helper function to toggle fav icon in lightgallery toolbar
  function updateToolbarFavouriteIcon(passedMediaID) {
    const container = document.getElementById('lightgallery')
    if (!container || !window.lgInstance) return
    let favBtn = document.querySelector('.lg-favourite-btn')
    if (favBtn) {
      let mediaID
      if (passedMediaID !== undefined) {
        mediaID = Number(passedMediaID)
      } else {
        let currentIndex = window.lgInstance.index
        let originalItems = container.querySelectorAll('a.lightgallery-item')
        if (originalItems[currentIndex]) {
          mediaID = Number(
            originalItems[currentIndex].getAttribute('data-mediaid'),
          )
        }
      }
      if (!mediaID) return
      const galleryID = getGalleryIdFromURL()
      const favourites = getFavourites(galleryID)
      const isFav = jQuery.inArray(mediaID, favourites) >= 0
      favBtn.innerHTML = isFav
        ? '<i class="fa-solid fa-heart"></i>'
        : '<i class="fa-light fa-heart"></i>'
    }
  }
  window.updateToolbarFavouriteIcon = updateToolbarFavouriteIcon

  $(document).on('click', '.favourite-media', function (e) {
    e.preventDefault()
    let currentMediaID = $(this).attr('data-mediaid')
    toggleFavourite(currentMediaID)
  })

  $(document).on(
    'click',
    '#media_settings_hero, #media_settings_image',
    function (e) {
      let galleryID = $('#gallery').data('galleryid')
      let mediaID = $(document).find('#media_settings').attr('data-mediaid')
      let isChecked = $('#media_settings_hero').prop('checked')
      let mediaImage = $('#media_settings_image')
      let rect = mediaImage[0].getBoundingClientRect()
      let xCoord = e.clientX - rect.left
      let yCoord = e.clientY - rect.top
      let xAsPercentage = 50
      let yAsPercentage = 50
      if ($(e.target).is('#media_settings_hero')) {
        if (!isChecked) {
          mediaID = ''
        }
      }
      if ($(e.target).is('#media_settings_image')) {
        $(document).find('#media_settings_hero').prop('checked', true)
        xAsPercentage = Math.round((xCoord / rect.width) * 100)
        yAsPercentage = Math.round((yCoord / rect.height) * 100)
      }

      $.ajax({
        url: '/inc/actions/ajax/gallery-poster.ajax.php',
        type: 'POST',
        dataType: 'json',
        data: {
          galleryID: galleryID,
          mediaID: mediaID,
          galleryPosterFocusX: xAsPercentage,
          galleryPosterFocusY: yAsPercentage,
        },
        success: function (response) {
          let posterURL = ''
          let posterID = ''
          if (response.mediaURL) {
            posterURL = response.mediaURL
            posterID = mediaID
          }
          $('.title-hero').css(
            'background-image',
            'linear-gradient(0deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.75) 100%), url(' +
              response.mediaURL +
              ')',
          )
          $('.title-hero').attr('data-posterid', mediaID)
          document.documentElement.style.setProperty(
            '--focus-x',
            `${xAsPercentage}%`,
          )
          document.documentElement.style.setProperty(
            '--focus-y',
            `${yAsPercentage}%`,
          )

          // let messageObject = [{
          //     type: {
          //         notificationTypeHideDelay: '2000',
          //         notificationTypeAutoHide: '1',
          //         notificationTypeIconClass: 'fa-light fa-square-check',
          //         notificationTypeName: 'Success'
          //     },
          //     timestamp: new Date().toLocaleString(),
          //     message: response.message
          // }];
          // make_toast(messageObject);
        },
        error: function (xhr, status, error) {
          //                console.error("Error occurred: " + status + ", " + error);
        },
      })
    },
  )

  $(document).on('click', '.generate-thumbnail', function (e) {
    e.preventDefault()

    let currentMediaID = $(this).attr('data-mediaid')

    const thumbIcon = $(this).find('.fa-image')
    toggleIcon(
      thumbIcon,
      true,
      'fa-light fa-image',
      'fa-thin fa-spinner fa-spin',
    )

    $.ajax({
      url: '/inc/actions/ajax/generate-thumbnails.ajax.php',
      type: 'POST',
      dataType: 'json',
      data: {
        mediaID: currentMediaID,
      },
      success: function (response) {
        toggleIcon(
          thumbIcon,
          false,
          'fa-light fa-image',
          'fa-thin fa-spinner fa-spin',
        )

        let messageObject = [
          {
            type: {
              notificationTypeHideDelay: '2000',
              notificationTypeAutoHide: '1',
              notificationTypeIconClass: 'fa-light fa-square-check',
              notificationTypeName: 'Success',
            },
            timestamp: new Date().toLocaleString(),
            message: response.message,
          },
        ]
        make_toast(messageObject)
        location.reload()
      },
      error: function (xhr, status, error) {
        console.error('Error occurred: ' + status + ', ' + error)
      },
    })
  })

  /* ------------------------------------------------
   * DOWNLAOD GUESTBOOK
   * ------------------------------------------------ */
  $(document).on('click', '.download-guestbook', function (e) {
    e.preventDefault()
    let galleryID = $(this).attr('data-galleryid')

    // Construct the download URL
    var downloadUrl =
      '/inc/actions/ajax/guestbook-download.ajax.php?galleryID=' + galleryID

    // Trigger the download by navigating to the URL
    window.location.href = downloadUrl

    // Immediately show a toast message indicating that the download should start
    let messageObject = [
      {
        type: {
          notificationTypeHideDelay: '2000',
          notificationTypeAutoHide: '1',
          notificationTypeIconClass: 'fa-light fa-square-check',
          notificationTypeName: 'Success',
        },
        timestamp: new Date().toLocaleString(),
        message: 'Your download is starting...',
      },
    ]
    make_toast(messageObject)
  })

  // ------------------------------------------------
  // AUDIO PLAYER MODAL
  // ------------------------------------------------
  const audioModalElement = document.getElementById('audioModal')
  let audioModal = null

  if (audioModalElement) {
    audioModal = new bootstrap.Modal(audioModalElement)

    audioModalElement.addEventListener('hidden.bs.modal', function () {
      const audioPlayer = document.getElementById('audio-player')
      if (audioPlayer) {
        audioPlayer.pause()
        audioPlayer.currentTime = 0 // Optional: Reset to start
      }
    })
  }

  // Function to populate the audio playlist from grid items
  function buildAudioPlaylist() {
    const playlist = []
    document
      .querySelectorAll('.media-item[data-mediatype="4"]')
      .forEach(item => {
        playlist.push({
          mediaid: item.getAttribute('data-mediaid'),
          mediaurl: item.getAttribute('data-mediaurl'),
          thumbnail: item.getAttribute('data-thumbnail'),
          sender: item.getAttribute('data-messagesender'), // consistent attribute
        })
      })
    return playlist
  }

  // Function to load an audio item into the modal main area
  function loadAudioItem(audioItem) {
    const player = document.getElementById('audio-player')
    player.pause()
    player.currentTime = 0
    document.getElementById('audio-thumbnail').src = audioItem.thumbnail
    document.getElementById('audio-source').src = audioItem.mediaurl
    player.load()
    player.play()
  }

  
  // Function to update the playlist UI in the sidebar
  function updatePlaylistUI(playlist, activeId) {
    const list = document.getElementById('audio-playlist')
    list.innerHTML = '' // Clear existing items

    playlist.forEach(item => {
      const li = document.createElement('li')
      li.className =
        'list-group-item' + (item.mediaid === activeId ? ' active' : '')
      li.textContent = item.sender // Display sender's name
      li.style.cursor = 'pointer'
      li.addEventListener('click', function () {
        // Remove active class from all
        list.querySelectorAll('li').forEach(el => el.classList.remove('active'))
        li.classList.add('active')
        loadAudioItem(item)
        // Optionally update the header as well if switching audio
        document.getElementById('audio-sender').textContent = item.sender
      })
      list.appendChild(li)
    })
  }

  // Click handler for audio items
  document.querySelectorAll('.audio-trigger').forEach(trigger => {
    trigger.addEventListener('click', function (e) {
      // Don't trigger modal if selection mode is active
      if (selectionMode) return

      e.preventDefault()
      // Get the clicked item details
      const parent = this.closest('.media-item')

      const activeMediaId = parent.getAttribute('data-mediaid')
      const activeMediaUrl = parent.getAttribute('data-mediaurl')
      const activeThumbnail = parent.getAttribute('data-thumbnail')
      const activeSender = parent.getAttribute('data-messagesender')

      const activeAudio = {
        mediaid: activeMediaId,
        mediaurl: activeMediaUrl,
        thumbnail: activeThumbnail,
        sender: activeSender,
      }

      // Build the playlist and update the sidebar
      const playlist = buildAudioPlaylist()
      updatePlaylistUI(playlist, activeMediaId)

      // Update the modal header with the sender's name
      document.getElementById('audio-sender').textContent = activeSender

      // Load the selected audio into the main area and play it
      loadAudioItem(activeAudio)

      if (audioModal) {
        audioModal.show()
      } else {
        console.error('audioModal is not initialized')
      }
    })
  })

})


$(document).on('submit', '#gallery_settings_form', function (e) {
    e.preventDefault();
    let currentGalleryId = $('#gallery_settings').data('galleryid');

    let galleryName = $('#galleryName').val();
    let galleryDescription = $('#galleryDescription').val();
    let expiryDate = $('#expiryDate').val();
    let galleryUserID = $('#galleryUserID').val();
    let isPrivate = $('#isPrivate').is(':checked');
    let isUploadable = $('#isUploadable').is(':checked');
    let isDownloadable = $('#isDownloadable').is(':checked');
    let guestbookEnabled = $('#guestbookEnabled').is(':checked');
    let audioGuestbookEnabled = $('#audioGuestbookEnabled').is(':checked');
    let galleryPassword = $('#galleryPassword').val();

    let qrFormat = $('#qrFormat').val();
    let qrImagePosition = 'center';
    let qrImage = '';
    
    let qrType = $('#qrType').val();
    // if(qrType !== 'standard') {
    //     qrImagePosition = $('#qrImagePosition').val();
    //     qrImage = $('#qrImage')[0].files.length > 0 ? $('#qrImage')[0].files[0] : '';
    // }
    if(qrType !== 'standard') {
        // qrImagePosition = $('#qrImagePosition').val();
        qrImagePosition = '';
        // qrImage = $('#qrImage')[0].files.length > 0 ? $('#qrImage')[0].files[0] : '';
        // qrImage = $('#qrImage')[0].files[0];
        qrImage = '';
    }
    let statusID = $('#statusID').val();

    // Create FormData and append data
    let formData = new FormData();
    formData.append('galleryID', currentGalleryId);
    formData.append('galleryName', galleryName);
    formData.append('galleryDescription', galleryDescription);
    formData.append('expiryDate', expiryDate);
    formData.append('isPrivate', isPrivate);
    formData.append('isUploadable', isUploadable);
    formData.append('isDownloadable', isDownloadable);
    formData.append('guestbookEnabled', guestbookEnabled);
    formData.append('audioGuestbookEnabled', audioGuestbookEnabled);
    formData.append('galleryPassword', galleryPassword);
    formData.append('qrType', qrType);
    formData.append('qrImagePosition', qrImagePosition);
    formData.append('qrFormat', qrFormat);
    formData.append('galleryUserID', galleryUserID);
    formData.append('statusID', statusID);

    if (qrImage) {  // Only append image if file is selected
        formData.append('qrImage', qrImage);
    }


    $.ajax({
        method: 'POST',
        url: '/inc/actions/ajax/save-gallery-settings.ajax.php',
        dataType: 'json',
        data: formData,
        processData: false,
        contentType: false,
    })
    .done(function (result) {
        if (result.success) {
            let messageObject = [{
                type: {
                    notificationTypeHideDelay: '2000',
                    notificationTypeAutoHide: '0',
                    notificationTypeIconClass: 'fa-light fa-square-check',
                    notificationTypeName: 'Success'
                },
                timestamp: new Date().toLocaleString(),
                message: result.message
            }];
            make_toast(messageObject);

        } else {
            let messageObject = [{
                type: {
                    notificationTypeHideDelay: '2000',
                    notificationTypeAutoHide: '0',
                    notificationTypeIconClass: 'fa-light fa-square-cross',
                    notificationTypeName: 'Error'
                },
                timestamp: new Date().toLocaleString(),
                message: result.message
            }];
            make_toast(messageObject);

        }
        // UPDATE GALLERY DETAILS
        //let targetGallery = $('#gallery_' + currentGalleryId);
        //targetGallery.find('#gallery_name').text(galleryName);
        //targetGallery.find('.gallery-last-updated').text('Just now');
        // targetGallery.prependTo(targetGallery.parent());

        // CLOSE MODAL
        //const gallery_settings_modal = bootstrap.Modal.getInstance(document.getElementById('gallery_settings'));    
        //gallery_settings_modal.hide();
        window.location.reload();
    })
    .fail(function (xhr, status, error) {
        // Handle the AJAX request failure if needed
        // console.error(xhr, status, error);
        // Handle the error here
    });

});

$(function () {

    $('#muid').on('change', function (e) {
        let selected = $(this).val();
        window.location.href = window.location.href.replace(/[\?#].*|$/, '?muid=' + selected);
    });

});







$(document).on('click','.toast-btn-close',function(){
    $(this).closest('.toast').toast('hide');
    $(this).closest('.toast').remove();
})


function make_toast(msg) {
    let wrapper = $('.toast-wrapper');
    // Check if the .toast-wrapper exists, if not create it and append it to a specified parent element
    if (wrapper.length === 0) {
        wrapper = $('<div class="toast-wrapper"></div>');
        // Append the wrapper to the body or another container where toasts should appear
        $('body').append(wrapper);
    }
    if (!Array.isArray(msg)) {
        msg = [msg];
    }
    let delay = 0;
    $.each(msg, function (index) {
        // Default values for optional properties
        let defaultHideDelay = '3000';  // Default delay in milliseconds
        let defaultAutoHide = 'true';   // Default auto-hide behavior
        let defaultIconClass = 'fa-light fa-square-info'; // Default icon class if none provided
        let defaultTypeName = 'Information';        // Default notification type name

        // Use defaults if specific properties are not available
        let hideDelay = this.type?.notificationTypeHideDelay || defaultHideDelay;
        delay += parseInt(hideDelay, 10);
        let autohide = this.type?.notificationTypeAutoHide === '0' ? 'false' : defaultAutoHide;
        let iconClass = this.type?.notificationTypeIconClass || defaultIconClass;
        let typeName = this.type?.notificationTypeName || defaultTypeName;
        let timestamp = this.timestamp || new Date().toLocaleTimeString();  // Current time as default timestamp
        let toast = `<div id="msg_${index}" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="${delay}" data-bs-autohide="${autohide}">
          <div class="toast-header">
            <i class="${iconClass} me-2"></i>
            <strong class="me-auto">${typeName}</strong>
            <small class="timestamp text-muted me-2" data-timestamp="${this.timestamp}">${timestamp}</small>
            <button type="button" class="ml-2 mb-1 btn-close toast-btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
          <div class="toast-body">
            <div class="body-message">${this.message}</div>
          </div>
        </div>`;

        wrapper.append(toast);
        $('.toast:not(.hide)').each(function () {
            $(this).toast('show');
        });
    });
}


function bakeCookie(name, value, days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        var expires = '; expires=' + date.toUTCString();
    } else {
        var expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
    // console.log('Mmmm.... smells scrumptious!');
}

function readCookie(name) {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    // console.log('Look ma... A Cookie!');
    return null;
}

function eatCookie(name) {
    // console.log('Yummm...That was delicious!');
    bakeCookie(name, '', -1);
}

$(document).on('click', '.delete-template', function (e) {
    e.preventDefault();
    let opentl = $(this).data('opentl');
    let closedtl = $(this).data('closedtl');
    if ($(this).siblings('.confirm-delete-template').hasClass('hide')) {
        $(this).text(opentl);
    } else {
        $(this).text(closedtl);
    }
    $(this).siblings('.confirm-delete-template').toggleClass('hide');
});

$(document).on('click', '.confirm-delete-template', function (e) {
    e.preventDefault();

    let templateID = $(this).data('templateid');
    $.ajax({
        method: 'POST',
        url: '/inc/actions/delete/template.delete.php',
        dataType: 'json',
        data: { action: 'delete', templateID: templateID }
    })
    .done(function (msg) {
        make_toast(msg);
        window.location.href = '/templates';
    });
});

document.addEventListener('DOMContentLoaded', function () {
  // Get the action tiles wrapper
  const actionTilesWrapper = document.querySelector('.action-tiles-wrapper')
  if (!actionTilesWrapper) return

  // Retrieve galleryID from data attribute
  const galleryID = actionTilesWrapper.getAttribute('data-galleryid')

  // Function to send GA4 event
  const sendGAEvent = (eventName, eventLabel) => {
    gtag('event', eventName, {
      event_category: 'engagement',
      event_label: eventLabel,
      gallery_id: galleryID, // Dynamically passed gallery ID
    })
  }

  // Attach event listeners to each button
  document
    .querySelector('#cta_upload_media')
    ?.addEventListener('click', function () {
      sendGAEvent('upload_media_click', 'Upload Media')
    })

  document
    .querySelector('#cta_sign_guestbook')
    ?.addEventListener('click', function () {
      sendGAEvent('sign_guestbook_click', 'Sign Guestbook')
    })

  document
    .querySelector('#cta_leave_voice')
    ?.addEventListener('click', function () {
      sendGAEvent('leave_voice_click', 'Leave Voice Message')
    })

  document
    .querySelector('#cta_view_gallery')
    ?.addEventListener('click', function () {
      sendGAEvent('view_gallery_click', 'View Gallery')
    })
})

document.addEventListener('DOMContentLoaded', function () {

    let addGalleryIsChecked = $('#addGallery').is(':checked');
    $('#new_gallery_wrapper').toggle(addGalleryIsChecked);



    $(document).on('click', '#addGallery', function (e) {
        let isChecked = $(this).is(':checked');
        $('#new_gallery_wrapper').toggle(isChecked);
    });

    var passwordField = document.getElementById('password');
    var confirmPasswordField = document.getElementById('password2');
    var passwordHelp = document.getElementById('passwordHelp');
    var password2Help = document.getElementById('password2Help');
    
    // Attach a click event listener to both password toggle icons
    document.querySelectorAll('.password-toggle').forEach(function(icon) {
        icon.addEventListener('click', function() {
            togglePasswordVisibility(passwordField, document.getElementById('password_icon'));
            togglePasswordVisibility(confirmPasswordField, document.getElementById('password2_icon'));
        });
    });
    
    // Attach input event listeners to validate passwords
    // passwordField.addEventListener('input', function() {
    //     validatePassword(passwordField, passwordHelp);
    //     validateConfirmPassword();
    // });
    // confirmPasswordField.addEventListener('input', function() {
    //     validatePassword(confirmPasswordField, password2Help);
    //     validateConfirmPassword();
    // });
    

    if (passwordField && confirmPasswordField) {
        passwordField.addEventListener('input', function() {
            validatePassword(passwordField, passwordHelp);
            validateConfirmPassword();
        });
        confirmPasswordField.addEventListener('input', function() {
            validatePassword(confirmPasswordField, password2Help);
            validateConfirmPassword();
        });
    // } else {
    //     console.log('Element(s) not found');
    }


    function togglePasswordVisibility(inputField, icon) {
        var isPasswordVisible = inputField.type === 'text';
        inputField.type = isPasswordVisible ? 'password' : 'text';
        icon.innerHTML = isPasswordVisible ? '<span class="fa-light fa-eye-slash fa-fw"></span>' : '<span class="fa-light fa-eye fa-fw"></span>';
    }
    
    function validatePassword(inputField, helpText) {
        var isInvalid = inputField.value.length < 8;
        helpText.textContent = isInvalid ? 'Password must be at least 8 characters long' : '';
        inputField.classList.toggle('is-invalid', isInvalid);
    }
    
    function validateConfirmPassword() {
        var isInvalid = passwordField.value !== confirmPasswordField.value;
        password2Help.textContent = isInvalid ? 'Passwords do not match' : '';
        confirmPasswordField.classList.toggle('is-invalid', isInvalid);
    }






    $(document).on('click', '#resend_email', function(e) {
        e.preventDefault();
        let messageTemplate = $('#messageTemplate').val();
        let userID = $('#userID').val();

        // DO AJAX
        $.ajax({
            method: 'POST',
            url: '/inc/actions/ajax/add-message-to-queue.ajax.php',
            dataType: 'json',
            data: { 
                messageTemplate: messageTemplate, 
                userID: userID 
            }
        })
        .done(function (result) {
            // console.log(result.message);
            window.location.reload();
        });

    });

    // MESSAGE PREVIEW TABLE
    $(document).on('click', '#mp_table tr', function(e) {
        e.preventDefault();
        let messageID = $(this).attr('data-id');
        // DO AJAX
        $.ajax({
            method: 'POST',
            url: '/inc/actions/ajax/get-message.ajax.php',
            dataType: 'json',
            data: { 
                messageID: messageID
            }
        })
        .done(function (result) {

            $('#mp_name').html(result.recipientName);
            $('#mp_contact').html('(' + result.recipientContact + ')');
            $('#mp_updated').html(result.dateUpdated);
            $('#mp_status').html(result.sendStatus);
            $('#mp_user').html(result.userName);
            $('#mp_gallery').html(result.galleryName);
            $('#mp_subject').html(result.messageSubject);
            $('#mp_body').html(result.messageBody);
        });
    });










    $(document).on('click', '.delete-user', function (e) {
        e.preventDefault();
        let opentl = $(this).data('opentl');
        let closedtl = $(this).data('closedtl');
        if ($(this).siblings('.confirm-delete-user').hasClass('hide')) {
            $(this).text(opentl);
        } else {
            $(this).text(closedtl);
        }
        $(this).siblings('.confirm-delete-user').toggleClass('hide');
    });

    $(document).on('click', '.confirm-delete-user', function (e) {
        e.preventDefault();

        let userID = $(this).data('userid');
        $.ajax({
            method: 'POST',
            url: '/inc/actions/delete/users.delete.php',
            dataType: 'json',
            data: { action: 'delete', userID: userID }
        })
        .done(function (msg) {
            make_toast(msg);
            window.location.href = '/users';
        });
    });






});